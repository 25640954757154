import './HomePage.css';

function HomePage() {
  return (
    <div id="homePage">
      <div className='text-align-center' >
        <h1>LOCUIM.ro</h1>
        <h4>Lansare 2024/2025</h4>
      </div>
    </div>
  );
}

export default HomePage;
